const React = require('react');
const ClipMultimedia = require('../../../clip-multimedia/clip-multimedia');
const { func, shape, string } = require('prop-types');
const { DEVICE_TYPE } = require('../../../../utils/constants');
const { PROVIDER } = require('../../../figure/constants');

const Clip = ({ item, namespace, clipsConfig, onClick, id }) => (
  <ClipMultimedia
    autoplay={clipsConfig?.autoplay}
    className={`${PROVIDER.CLIP} ${namespace}__clip`}
    deviceType={DEVICE_TYPE.MOBILE}
    onClick={onClick}
    id={id}
    {...item}
  />
);

Clip.propTypes = {
  item: shape({}).isRequired,
  namespace: string.isRequired,
  clipsConfig: shape({}),
  deviceType: string.isRequired,
  onClick: func.isRequired,
  id: string.isRequired,
};

module.exports = Clip;
