const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'clip';
const namespace = 'ui-pdp-icon ui-pdp-icon--clip';

const IconClip = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    fill="none"
    viewBox="0 0 15 14"
  >
    <path
      fill="color(display-p3 0.2039 0.5137 0.9804)"
      d="M6.9.782L1.568 6.114a4.338 4.338 0 006.135 6.135l6.04-6.04a3.27 3.27 0 00-4.625-4.624L3.833 6.869a2.202 2.202 0 103.115 3.115l4.577-4.577-.849-.848L6.1 9.135a1.002 1.002 0 01-1.418-1.417l5.284-5.285a2.07 2.07 0 012.928 2.928l-6.04 6.04a3.138 3.138 0 11-4.437-4.438L7.749 1.63 6.9.782z"
    />
  </svg>
);

IconClip.propTypes = {
  className: string,
};

IconClip.defaultProps = {
  className: null,
};

module.exports = React.memo(IconClip);
module.exports.ICON_ID = ICON_ID;
