const PROVIDER = {
  IMAGE: 'image',
  YOUTUBE: 'youtube',
  CLIP: 'clip',
  MATTERPORT: 'matterport',
  SPINCAR: 'spincar',
};

const MULTIMEDIA_PROVIDERS = [PROVIDER.MATTERPORT, PROVIDER.SPINCAR, PROVIDER.YOUTUBE];

module.exports = {
  PROVIDER,
  MULTIMEDIA_PROVIDERS,
};
